// extracted by mini-css-extract-plugin
export var aiChat__aiPowered = "br_lH";
export var aiChat__animate = "br_lw";
export var aiChat__animate_content = "br_lx";
export var aiChat__answer = "br_lD";
export var aiChat__content = "br_lz";
export var aiChat__icon = "br_ly";
export var aiChat__loadingState = "br_lB";
export var aiChat__message = "br_lv";
export var aiChat__question = "br_lC";
export var aiChat__sender = "br_lF";
export var aiChat__senderInfo = "br_lG";
export var pulseSize = "br_lr";