import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { FormEvent, useEffect, useState } from 'react'

import ChatLayout from '~/components/layouts/ChatLayout'
import Loader from '~/components/shared/Loader/Loader'
import ReCaptchaProvider from '~/components/shared/ReCaptchaProvider'
import usePopup from '~/hooks/usePopup'
import { RecommendedCategories } from '~/mock/ai-chat/RecommendedCategories'
import { StrapiApi } from '~/services/Api'
import AIChatClear from '~/views/AIChat/components/AIChatClear'
import AIChatForm from '~/views/AIChat/components/AIChatForm'
import AIChatMessage from '~/views/AIChat/components/AIChatMessage'
import AIChatModalForm from '~/views/AIChat/components/AIChatModalForm'
import AIChatPreview from '~/views/AIChat/components/AIChatPreview'

import * as containerStyles from './AIChat.module.scss'
import AIChatIdleCtaPopup from './components/AIChatIdleCtaPopup'
import AIChatNav from './components/AIChatNav'
import './AIChat.scss'
import useAiChat from './hooks/useAiChat'
import { createChatMessage, handleError } from './utils/chat.utils'

const AIChat = () => {
  const breakpoints = useBreakpoint()
  const [scrollToBottom, setScrollToBottom] = useState(false)
  const {
    chatState: {
      isChatStarted,
      isLoadingAnswer,
      chatHistory,
      input,
      isLoading,
    },
    handleClearChat,
    updateChatState,
    getBotAnswer,
    appendToLastMessage,
    updateLastMessage,
    createSession,
  } = useAiChat()
  const {
    handleCloseIdlePopup,
    popupState: { showIdlePopup },
  } = usePopup('aiChat')

  const handleSubmit = async (e?: FormEvent) => {
    e?.preventDefault()

    try {
      setScrollToBottom(true)
      updateChatState({
        isLoadingAnswer: true,
        chatHistory: [createChatMessage('human', input)],
        input: '',
      })

      await getBotAnswer(input)
    } catch {
      handleError()
    } finally {
      updateChatState({ isLoadingAnswer: false })
    }
  }

  const handleRecommendedQuestion = async (text: string) => {
    try {
      setScrollToBottom(true)

      const sessionId = await createSession()
      const answer = RecommendedCategories[text]
      const humanMessage = createChatMessage('human', text)
      const aiMessage = createChatMessage('ai', answer)

      await StrapiApi.saveToChatHistory(sessionId, [humanMessage, aiMessage])

      updateChatState({
        isLoadingAnswer: true,
        chatHistory: [humanMessage, createChatMessage('ai', '')],
      })

      let currentIndex = 0
      const typeOutAnswer = () => {
        const chunk = answer.slice(currentIndex, currentIndex + 5)
        appendToLastMessage(chunk)

        currentIndex += 5

        if (currentIndex < answer.length) {
          requestAnimationFrame(typeOutAnswer)
        } else {
          updateLastMessage(answer)
          updateChatState({ isLoadingAnswer: false })
        }
      }

      typeOutAnswer()
    } catch {
      handleError()
    }
  }

  useEffect(() => {
    setScrollToBottom(true)
  }, [])

  return (
    <ChatLayout>
      <section className={containerStyles.aiChat}>
        <AIChatNav
          handleClearChat={handleClearChat}
          isChatStarted={isChatStarted}
        />

        <AIChatIdleCtaPopup
          show={showIdlePopup}
          handleClose={handleCloseIdlePopup}
        />

        <h1 className={containerStyles.aiChat__h1}>AI assistant from Codica</h1>

        {isLoading && <Loader />}

        {!isLoading && isChatStarted === true && (
          <div className={containerStyles.aiChat__container}>
            <div className={containerStyles.aiChat__messagesRecommendedWrapper}>
              {chatHistory.map((message, index) => {
                const isLastMessage = index === chatHistory.length - 1

                return (
                  <AIChatMessage
                    key={index}
                    type={message.type}
                    content={message.content}
                    scrollToBottom={scrollToBottom}
                    isLoadingAnswer={isLoadingAnswer && isLastMessage}
                  />
                )
              })}
            </div>
            <div className={containerStyles.aiChat__sticky_form}>
              {breakpoints.mdUp && <AIChatClear onClear={handleClearChat} />}

              <div>
                <AIChatForm
                  input={input}
                  isLoading={isLoadingAnswer}
                  handleInputChange={(e) =>
                    updateChatState({ input: e.target.value })
                  }
                  handleSubmit={handleSubmit}
                />
                <ReCaptchaProvider>
                  <AIChatModalForm />
                </ReCaptchaProvider>
              </div>
            </div>
          </div>
        )}

        {!isLoading && isChatStarted === false && (
          <AIChatPreview
            input={input}
            isLoading={isLoadingAnswer}
            handleInputChange={(e) =>
              updateChatState({ input: e.target.value })
            }
            handleSubmit={handleSubmit}
            handleChatStart={() => updateChatState({ isChatStarted: true })}
            handleRecommendedQuestion={handleRecommendedQuestion}
          />
        )}
      </section>
    </ChatLayout>
  )
}

export default AIChat
